import MuiOutlinedInput from "@mui/material/OutlinedInput";

import styles from "./FormInput.module.scss";

export type FormInputProps = {
    autocomplete?: string;
    className?: string;
    endElement?: React.ReactNode;
    id: string;
    inputClassName?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    isDisabled?: boolean;
    multiline?: boolean;
    name: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    placeholder?: string;
    readOnly?: boolean;
    type?: "email" | "number" | "text" | "password" | "tel";
    value?: string;
};

export function FormInput({
    autocomplete = "on",
    className,
    endElement,
    id,
    inputClassName = "",
    inputRef,
    isDisabled = false,
    multiline,
    name,
    onChange,
    onClick,
    onFocus,
    placeholder,
    readOnly = false,
    type = "text",
    value,
}: FormInputProps) {
    return (
        <MuiOutlinedInput
            autoComplete={autocomplete}
            className={`${styles["fieldset"]} ${isDisabled ? styles["fieldset--is-disabled"] : ""}`}
            classes={{ notchedOutline: className }}
            disabled={isDisabled}
            endAdornment={endElement}
            fullWidth
            id={id}
            inputProps={{
                className: `${styles["input"]} ${inputClassName}`,
            }}
            inputRef={inputRef}
            minRows={3}
            multiline={multiline}
            name={name}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            type={type}
            value={value}
        />
    );
}
