import { useId, useState } from "react";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Stack } from "../../Layout";
import { TextFieldProps } from "../FormText/TextField";
import {
    ButtonCustom,
    ChevronBottomIcon,
    ChevronTopIcon,
    Divider,
    Typography,
} from "../../General";
import { Popover } from "../../Overlay";
import { FormControl, FormHelperText, FormInput, FormLabel } from "../_partials";
import { Country, CountryItem, isoToEmoji } from "./PhoneFieldCountryItem";

import countryCodes from "./country-codes.json";

import styles from "./PhoneField.module.scss";

polyfillCountryFlagEmojis();

export const defaultValueNames = {
    dialCode: "dialCode",
    phoneNumber: "phoneNumber",
};

export type DefaultValueNames = {
    dialCode: string;
    phoneNumber: string;
};

export interface PhoneFieldProps<TValueNames extends Partial<DefaultValueNames> = DefaultValueNames>
    extends Omit<TextFieldProps, "onChange" | "type" | "value"> {
    defaultCode?: Country["code"];
    preferedCountries?: Country["code"][];
    value: Record<keyof TValueNames, string>;
    onChange: (value: Record<keyof TValueNames, string>) => void;
    valueNames?: Partial<DefaultValueNames>;
}

export function PhoneField<TValueNames extends Partial<DefaultValueNames> = DefaultValueNames>({
    defaultCode = "FR",
    helperText,
    isError,
    label,
    name,
    onChange,
    preferedCountries = ["FR", "DE", "IT", "ES", "GB", "US"],
    value,
    valueNames,
    ...rest
}: PhoneFieldProps<TValueNames>) {
    const id = useId();
    const names = { ...defaultValueNames, ...valueNames };
    const defaultCountry = countryCodes.find((country) => country.code === defaultCode) as Country;
    const prefered = countryCodes.filter((country) => preferedCountries.includes(country.code));

    const mergedCountries = [...prefered, null, ...countryCodes];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<Country>(defaultCountry);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleOnClose = () => {
        setIsOpen(false);
    };

    const handleOnChange = ({ dialCode, phoneNumber }: DefaultValueNames) => {
        onChange({
            ...{ [names.dialCode as keyof TValueNames]: dialCode },
            ...{ [names.phoneNumber as keyof TValueNames]: phoneNumber },
        } as Record<keyof TValueNames, string>);
    };

    const handleSelectCountry = (country: Country) => {
        handleOnChange({
            dialCode: country.dial_code,
            phoneNumber: value?.[names.phoneNumber as keyof TValueNames],
        });
        setSelectedCountry(country);
        setIsOpen(false);
    };

    const renderRow = ({ index, style }: ListChildComponentProps) => {
        const country = mergedCountries[index];

        if (country === null) {
            return (
                <div className={styles["input-phone__divider"]} style={{ ...style }}>
                    <Divider />
                </div>
            );
        }

        return (
            <CountryItem
                key={country.code}
                country={country}
                onClick={() => handleSelectCountry(country)}
                style={style}
            />
        );
    };

    return (
        <FormControl isError={isError}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Stack className={styles["input-phone"]} direction="row">
                <ButtonCustom className={styles["input-phone__button"]} onClick={handleOpen}>
                    <Stack component="span" direction="row" gap={0.5}>
                        <Typography
                            className={`${styles["input-phone__country-name"]} ${styles["input-phone__flag"]}`}
                            component="span"
                            variant="text-sm"
                        >
                            {isoToEmoji(selectedCountry.code)}
                        </Typography>
                        <Typography component="span" variant="text-sm">
                            {selectedCountry.dial_code}
                        </Typography>
                    </Stack>
                    {isOpen ? <ChevronTopIcon /> : <ChevronBottomIcon />}
                </ButtonCustom>
                <FormInput
                    id={id}
                    name={name}
                    onChange={(e) => {
                        handleOnChange({
                            dialCode: selectedCountry.dial_code,
                            phoneNumber: e.target.value,
                        });
                    }}
                    type="tel"
                    value={value?.[names.phoneNumber as keyof TValueNames]}
                    {...rest}
                />
            </Stack>
            <Popover isOpen={isOpen} onClose={handleOnClose}>
                <Stack className={styles["input-phone__countries"]}>
                    <FixedSizeList
                        height={300}
                        itemCount={mergedCountries.length}
                        itemSize={40}
                        width={300}
                    >
                        {renderRow}
                    </FixedSizeList>
                </Stack>
            </Popover>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
}
