import MuiPortal from "@mui/material/Portal";
import type { PortalProps as MuiPortalProps } from "@mui/material/Portal";

export function Portal({ children, container, disablePortal }: MuiPortalProps) {
    return (
        <MuiPortal container={container} disablePortal={disablePortal}>
            {children}
        </MuiPortal>
    );
}
