import { ForwardRefRenderFunction, forwardRef, useId } from "react";
import { FormControl, FormInput, FormLabel } from "../../../_partials";
import { Stack } from "../../../../Layout";

type InputProps = {
    inputName: string;
    inputRef?: React.Ref<HTMLInputElement>;
    isError?: boolean;
    label: string;
};

// eslint-disable-next-line react/function-component-definition
const CustomInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
    { label, inputName, inputRef, isError, ...rest },
    ref,
) => {
    const id = useId();
    return (
        <div ref={ref}>
            <FormControl isError={isError}>
                <Stack gap={0.5}>
                    <FormLabel htmlFor={id}>{label}</FormLabel>
                    <FormInput {...rest} id={id} inputRef={inputRef} name={inputName} readOnly />
                </Stack>
            </FormControl>
        </div>
    );
};

export const Input = forwardRef(CustomInput);
