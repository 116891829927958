// themeUtils.ts
import {
    createTheme as muiCreateTheme,
    CssVarsThemeOptions,
    SimplePaletteColorOptions,
    darken,
    getContrastRatio,
} from "@mui/material/styles";
import { createTheme } from "../createTheme";
import { COLOR } from "../config/theme.constants";

export const generatePrimaryLink = (
    primaryColor: string,
    backgroundColor: string,
    contrastThreshold: number = 3,
) => {
    let adjustedColor = primaryColor;

    const contrastRatio = getContrastRatio(primaryColor, backgroundColor);

    if (contrastRatio >= contrastThreshold) {
        return primaryColor;
    }

    let adjustmentFactor = 0.05;

    while (getContrastRatio(adjustedColor, backgroundColor) < contrastThreshold) {
        adjustedColor = darken(adjustedColor, adjustmentFactor);
        adjustmentFactor += 0.05;
        if (adjustmentFactor > 1) break;
    }

    return adjustedColor;
};

export const generateTheme = (theme: CssVarsThemeOptions) => {
    const { palette } = muiCreateTheme();

    const primary =
        (theme.colorSchemes?.light?.palette?.primary as SimplePaletteColorOptions)?.main ||
        COLOR.PRIMARY;
    const background = theme.colorSchemes?.light?.palette?.background?.default || COLOR.BACKGROUND;

    const themeWithPrimaryLink = {
        ...theme,
        colorSchemes: {
            light: {
                palette: {
                    ...theme.colorSchemes?.light?.palette,

                    primaryLink: palette.augmentColor({
                        color: { main: generatePrimaryLink(primary, background) },
                    }),
                },
            },
        },
    };

    return createTheme({
        components: {
            MuiAlert: { styleOverrides: { root: { fontSize: "1em" }, icon: { fontSize: "1em" } } },
            MuiAlertTitle: { styleOverrides: { root: { fontSize: "1em" } } },
            MuiInputBase: { styleOverrides: { root: { fontSize: "1em" } } },
            MuiMenuItem: { styleOverrides: { root: { fontSize: "1em" } } },
            MuiModal: { styleOverrides: { root: { fontSize: "max(16px, 1rem)" } } },
            MuiTypography: { styleOverrides: { root: { fontSize: "1em", padding: 0 } } },
            MuiSvgIcon: {
                styleOverrides: {
                    root: ({ ownerState }) => {
                        if (ownerState.fontSize === "large") return { fontSize: "2.1875em" };
                        if (ownerState.fontSize === "small") return { fontSize: "1.25em" };
                        return { fontSize: "1.5em" };
                    },
                },
            },
        },
        ...themeWithPrimaryLink,
    });
};
