import { ReactNode, forwardRef } from "react";

import MuiStack from "@mui/material/Stack";
import type { StackProps as MuiStackProps } from "@mui/material/Stack";

export interface StackProps
    extends Pick<
        MuiStackProps,
        "className" | "direction" | "gap" | "justifyContent" | "alignItems"
    > {
    component?: "div" | "span";
    children: ReactNode;
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
    ({ children, component = "div", ...rest }, ref) => (
        <MuiStack ref={ref} component={component} useFlexGap {...rest}>
            {children}
        </MuiStack>
    ),
);

Stack.displayName = "Stack";
