import MuiFormLabel from "@mui/material/FormLabel";

import { Typography } from "../../../General";

import Styles from "./FormLabel.module.scss";

export type FormLabelProps = {
    children: string;
    htmlFor: string;
    id?: string;
};

export function FormLabel({ children, htmlFor, id }: FormLabelProps) {
    return (
        <MuiFormLabel className={Styles.FormLabel} htmlFor={htmlFor} id={id}>
            <Typography component="span" variant="label">
                {children}
            </Typography>
        </MuiFormLabel>
    );
}
