import { Typography } from "../../../../General";

import styles from "./Day.module.scss";

type DayProps = {
    day: number;
    info?: string | number;
    size?: "medium" | "large";
};

export function Day({ day, info, size = "medium" }: DayProps) {
    return (
        <div className={`${styles["day"]} ${styles[`day--${size}`]}`}>
            <div className={styles["day__content"]}>
                <Typography component="span" variant="text-sm">
                    {day}
                </Typography>
                {info ? (
                    <Typography component="span" variant="text-xs">
                        {info}
                    </Typography>
                ) : null}
            </div>
        </div>
    );
}
